'use strict';

import { loadItemcardGalleryImages, loadItemcardGalleryImagesByScrolling } from './partials/loadItemcardGalleryImages';
import { openFullscreenGallery } from './partials/fullscreengallery';
import {variantSlider} from './partials/variantSlider';
import { loadMoreMedia } from './partials/loadMoreMedia';
import { createTeamOrderEventListener } from './partials/toggleTeamOrderSelect';
import { createDescriptionEventListener } from './partials/descriptionEventListener';
import {initQuantitySpinners} from '../../../../global/main/src/js/quantitySpinner';
import {initSubmitOrderButton, initUpdateFormQty, updateFormQty} from './partials/updateOrderbutton';
import {toggleAvailabilityElement} from "./partials/updateAvailability";
import {calcFooterSpaceForStickyOrderBox} from "./partials/calcFooterSpaceForStickyOrderBox";

function assignCustomSelectTabHeaders() {
    const customSelectTabHeaders = document.querySelectorAll('.productPageSideOrderTableSelect__customSelectTabHeader>ul');

    customSelectTabHeaders.forEach(customSelectTabHeader => {
        let isDown = false;
        let startX;
        let scrollLeft;

        customSelectTabHeader.addEventListener('mousedown', (e) => {
            isDown = true;
            customSelectTabHeader.classList.add('active');
            startX = e.pageX - customSelectTabHeader.offsetLeft;
            scrollLeft = customSelectTabHeader.scrollLeft;
        });

        customSelectTabHeader.addEventListener('mouseleave', () => {
            isDown = false;
            customSelectTabHeader.classList.remove('active');
        });

        customSelectTabHeader.addEventListener('mouseup', () => {
            isDown = false;
            customSelectTabHeader.classList.remove('active');
        });

        customSelectTabHeader.addEventListener('mousemove', (e) => {
            if (!isDown) return; // Stop the function if the mouse is not down
            e.preventDefault();
            const x = e.pageX - customSelectTabHeader.offsetLeft;
            const walk = (x - startX) * 2; // The scroll-fastness factor, adjust as needed
            customSelectTabHeader.scrollLeft = scrollLeft - walk;
        });
    });
}

function addListenersToOpenGallery(productPage) {
    for (let item of productPage.querySelectorAll('.js-openGallery')) {
        item.addEventListener('click', function (event) {
            openFullscreenGallery(item);
        });
    }
}

function assignQuantitySpinners(productPage) {
    initQuantitySpinners(productPage,function (input) {
        if (input.getAttribute('id') === 'productPageQuantitySingle') {
            updateFormQty(input.value);
        }
    });
}

function loadItemcardGallery(productPage) {
    if (window.innerWidth > 767) {
        loadItemcardGalleryImages(productPage.querySelectorAll('.productPageImagesGrid__item:not(.productPageImagesGrid__item--more) img'));
    } else {
        loadItemcardGalleryImagesByScrolling(productPage.querySelector('.productPageImagesGrid'));
    }
}

window.afterDOMContentLoadedDetailPage = function() {
    const productPage = document.getElementById('productPage');

    if (productPage) {
        createTeamOrderEventListener();
        createDescriptionEventListener();
        addListenersToOpenGallery(productPage);
        loadItemcardGallery(productPage);
        loadMoreMedia(productPage);
        variantSlider(productPage);
        assignQuantitySpinners(productPage);
        initUpdateFormQty();
        initSubmitOrderButton();
        toggleAvailabilityElement(true);
        calcFooterSpaceForStickyOrderBox(productPage);
        assignCustomSelectTabHeaders();


        window.initQuantitySpinners = assignQuantitySpinners;
        window.assignCustomSelectTabHeaders = assignCustomSelectTabHeaders;
        window.initUpdateFormQty = initUpdateFormQty;
        window.initSubmitOrderButton = initSubmitOrderButton;
        window.toggleAvailabilityElement = toggleAvailabilityElement;
        window.calcFooterSpaceForStickyOrderBox = calcFooterSpaceForStickyOrderBox;
        window.createTeamOrderEventListener = createTeamOrderEventListener;
        window.addListenersToOpenGallery = addListenersToOpenGallery;
        window.loadItemcardGallery = loadItemcardGallery;
        window.loadMoreMedia = loadMoreMedia;
        window.variantSlider = variantSlider;
        window.createDescriptionEventListener = createDescriptionEventListener;
    }
};

document.addEventListener('DOMContentLoaded', () => {
    window.afterDOMContentLoadedDetailPage();
});

