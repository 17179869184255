'use strict';
import {scrollToView} from "../../../../main/js/scrollToView";
import {checkTeamorderTableSelected} from "./teamorderTable";

const addItemToBasketButton = document.getElementById('addToBasketButton');
const teamOrderSwitch = document.querySelector('.js-switchProductPageSwitch');
const productPageSideOrderTable = document.querySelector('.productPageSideOrderTable');
const formSingle = document.getElementById('itemcard_order_button_form_std');
const formItemIdInput = formSingle.querySelector("input[name='item_id']");
const formVariantCodeInput = formSingle.querySelector("input[name='item_var_code']");
const formQtyInput = formSingle.querySelector("input[name='item_qty']");
const formTeam =  document.getElementById('add_item_to_basket_list');

function checkDisabledButton(){
    let disable = true;

    if(formQtyInput){
        if(parseInt(formQtyInput.value) > 0){
            disable = false;
        }else{
            disable = true;
        }
    }

    if(teamOrderSwitch && teamOrderSwitch.checked){
        disable = !checkTeamorderTableSelected();
    }else{
        if(formVariantCodeInput){
            if(formVariantCodeInput.value !== ""){
                disable = false;
            }else{
                disable = true;
            }
        }
    }

    return disable;
}

function changeSubmitButtonText(){
    let disabledBasketButtonText = "Please choose size";
    let enabledBasketButtonText = "Add to basket";

    if (typeof tc_please_choose_size !== 'undefined') {
        disabledBasketButtonText = tc_please_choose_size;
    }
    if (typeof tc_add_to_basket !== 'undefined') {
        enabledBasketButtonText = tc_add_to_basket;
    }

    let addItemToBasketButtonText = addItemToBasketButton.querySelector("span");
    if(addItemToBasketButtonText){
        if(checkDisabledButton()){
            addItemToBasketButtonText.innerText = disabledBasketButtonText;
        }else{
            addItemToBasketButtonText.innerText = enabledBasketButtonText;
        }
    }
}

export function updateOrderButton(){
    if(checkDisabledButton()){
        addItemToBasketButton.classList.add("button_disabled");
    }else{
        addItemToBasketButton.classList.remove("button_disabled");
    }

    changeSubmitButtonText();
}

export function updateFormItemID(itemID){
    if(formSingle){
        if(formItemIdInput){
            formItemIdInput.value = itemID;
        }
    }
}
export function updateFormVariantCode(variantCodeId){
    if(formSingle){
        if(formVariantCodeInput){
            formVariantCodeInput.value = variantCodeId;
        }
    }
}
export function updateFormQty(qty){
    if(formSingle){
        if(formQtyInput){
            formQtyInput.value = qty;
        }
    }
}

export function initUpdateFormQty(){
    const qtyInput  = document.getElementById("productPageQuantitySingle");
    if(qtyInput){
        qtyInput.addEventListener("change",function (){
            updateFormQty(qtyInput.value);
        });
    }
}

export function initSubmitOrderButton(){
    if(addItemToBasketButton){
        addItemToBasketButton.addEventListener("click",function (e){
            let form = formSingle;
            let isSingle = true;

            if(teamOrderSwitch && teamOrderSwitch.checked){
                form = formTeam;
                isSingle = false;
            }
            if(form){
                if(!addItemToBasketButton.classList.contains("button_disabled")){
                    form.submit();
                }else{
                    let spacer = 0;
                    if(document.querySelector(".headerMain")){
                        spacer = document.querySelector(".headerMain").getBoundingClientRect().height + 20;
                    }
                    scrollToView(productPageSideOrderTable,spacer);

                    let sizeWrapper = document.querySelector(".productPageSideOrderTableSelect--single");
                    if(!isSingle){
                        sizeWrapper = document.querySelector(".productPageSideOrderTableSelect--teamorder");
                    }
                    if(sizeWrapper){
                        const target = sizeWrapper.querySelector(".js-loadProductPageSelection");
                        if(target){
                            target.click();
                        }
                    }
                    e.preventDefault();
                    e.stopImmediatePropagation();
                    e.stopPropagation();
                }
            }
        });
    }
}